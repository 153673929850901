import request from '../axios'
import { saveAs } from 'file-saver'
/**
 * 获取任务目录列表
 */
export function getIndexCategoryList() {
  return request({
    url: '/api/getIndexCategory',
  })
}


export function UpdateIndexCategoryConent(id,params) {
  return request({
    method: 'post',
    url: `/api/indexCategory/${id}/saveContent`,
    data:params,
  })
}

export function updateIndexCategory(id, params) {
  return request({
    method: 'post',
    url: `/api/indexCategory/${id}`,
    data: params,
  })
}

export function createIndexCategory(params) {
  return request({
    method: 'post',
    url: `/api/indexCategory`,
    data: params,
  })
}



/**
 * 删除任务目录
 * @param { string } id
 */
export function deleteIndexCategory(id) {
  return request({
    method: 'post',
    url: `/api/indexCategory/del/${id}`,
  })
}

/**
 * 获取任务
 * @param { string } [id] 目录id
 */
export function getIndexCategoryPage(params) {
  const cId = params.cId
  if (cId) {
    return request({
      url: `/api/indexCategory/${cId}/recods`,
      params,
    })
  } else {
    return request({
      url: '/api/users/recods',
      params,
    })
  }
}

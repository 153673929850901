<!--
 * @Author: jiang
 * @Date: 2021-06-12 11:39:09
 * @Description: 目录分类
-->
<template>
  <div>
    <c-tree ref="tree" :data="treeData" :current="currentKey"  :defaultExpandAl="false" :submit-loading="submitLoading" title="新增数据类目"
      create-text="新增数据类目" :form-option="option" @change="onChange" @add="onAdd" @edit="onEdit"
      @delete="onDelete"></c-tree>
  </div>
</template>

<script>
  import CTree from '@/components/tree'
  import {
    getIndexCategoryList,
    createIndexCategory,
    updateIndexCategory,
    deleteIndexCategory,
  } from '@/api/special/IndexCategory'
  import {
    listToTree
  } from '@/utils/tree'

  export default {
    components: {
      CTree,
    },
    props: {
      currentKey: [Number, String],
    },

    data() {
      return {
        treeData: [],
        submitLoading: false,
        option: {
          columns: [{
              label: '名称',
              prop: 'name',
              inputAttrs: {
                placeholder: '输入名称',
              },
              rules: [{
                required: true,
                message: '必须填写字段',
                trigger: 'blur'
              }],
            },
            {
              label: '排序(数越大越靠前)',
              prop: 'sort',
              inputAttrs: {
                placeholder: '输入排序数字，数越大越靠前',
              },
            }
          ],
        },
      }
    },
    created() {
      this.fetchCategory()
    },
    computed: {
      userId() {
        return this.$store.getters['user/id']
      },
      userDepartmentId() {
        return this.$store.state.user.userInfo.department.id
      },
    },
    methods: {
      getName(id) {
        return this.$refs.tree.getName(id)
      },
      fetchCategory() {
        getIndexCategoryList().then(res => {

          this.treeData = listToTree(res.data, {
            id: 'id',
            parentId: 'parent_id',
            children: 'children'
          })
          this.treeData.unshift({id:"",name:"全部"})

        })
      },
      onChange(data) {
        this.$emit('change', data)
      },
      onAdd(data) {
        this.submitLoading = true
        const params = {
          name: data.data.name,
          sort: data.data.sort||0 ,
          parent_id: data.tree.id,
        }
        createIndexCategory(params)
          .then(res => {
            this.fetchCategory()
            this.$message.success('添加成功')
          })
          .finally(() => {
            this.submitLoading = false
            this.$refs.tree.closeDialog()
          })
      },
      onEdit(data) {
        this.submitLoading = true
        const params = {
          name: data.data.name,
          sort: data.data.sort,
          parent_id: data.tree.parent_id,
        }
        updateIndexCategory(data.tree.id, params)
          .then(res => {
            this.$message.success('修改成功')
            this.fetchCategory()
          })
          .finally(() => {
            this.submitLoading = false
            this.$refs.tree.closeDialog()
          })
      },
      onDelete(data) {
        this.$confirm('确定要删除当前项吗', '提示', {
            type: 'warning'
          })
          .then(() => {
            return deleteIndexCategory(data.id)
          })
          .then(() => {
            this.$message.success('删除成功')
            this.fetchCategory()
          })
          .catch(() => {})
      },
    },
  }
</script>
